<template>
  <c-box
    width="100%"
    border-radius="12px"
    background-color="white"
    border="1px solid #EDF2F7"
  >
    <c-box
      width="100%"
      overflow-x="auto"
    >
      <c-box
        class="base-table__table"
        as="table"
        width="100%"
        border="0"
        cellspacing="0"
        cellpadding="0"

        font-size="14px"
      >
        <c-box
          class="base-table__thead"
          as="thead"
          color="primary.400"
          background-color="primary.50"
        >
          <c-box
            class="base-table__tr"
            as="tr"
          >
            <c-box
              v-for="column in columns"
              :key="column.id"
              :class="{
                'base-table__th': true,
                'sortable': column.sortable,
              }"
              class="base-table__th"
              as="th"
              font-size="14px"
              font-weight="400"
              font-family="Roboto, sans-serif, Arial"
              padding="10.5px 14px"
              :text-align="column.align || 'left'"
              :width="column.width ? column.width : ''"
              @click="handleChangeSort(column.id, column.sortable)"
            >
              <c-box
                :width="column.width || '100%'"
                display="flex"
                align-items="center"
                :justify-content="column.align || 'flex-start'"
                gap="8px"
              >
                <span>{{ column.label }}</span>
                <span v-show="sort?.[column?.id]?.toUpperCase() === 'ASC'">
                  ▲
                </span>
                <span v-show="sort?.[column?.id]?.toUpperCase() === 'DESC'">
                  ▼
                </span>
              </c-box>
            </c-box>
          </c-box>
        </c-box>
        <c-box
          class="base-table__tbody"
          as="tbody"
        >
          <c-box
            v-for="(item, itemIdx) in items"
            :key="itemIdx"
            class="base-table__tr"
            as="tr"
            :background="item.isHighlight ? '#FFFCDD' : ''"
          >
            <c-box
              v-for="(column, columnIdx) in columns"
              :key="columnIdx"
              class="base-table__td"
              as="td"
              padding="10.5px 14px"
              :text-align="column.itemAlign || 'left'"
            >
              <c-text
                v-if="column.type === 'AUTO_INCREMENT'"
              >
                {{ (page-1) * perPage + (itemIdx+1) }}
              </c-text>
              <c-text
                v-if="column.type === 'TEXT'"
              >
                <c-text
                  v-if="column?.customRender"
                  as="span"
                >
                  {{ column?.customRender(item) || '-' }}
                </c-text>
                <c-text
                  v-else
                  as="span"
                >
                  {{ item[column.id] || '-' }}
                </c-text>
              </c-text>
              <c-box
                v-if="column.type === 'TEXT_WITH_SUBTEXT'"
              >
                <c-box
                  v-if="column?.customRender"
                >
                  <c-text>
                    {{ column?.customRender(item)?.text || '-' }}
                  </c-text>
                  <c-text color="gray.900">
                    {{ column?.customRender(item)?.subtext || '-' }}
                  </c-text>
                </c-box>
                <c-box
                  v-else
                >
                  <c-text>
                    {{ item[column.id]?.text || '-' }}
                  </c-text>
                  <c-text color="gray.900">
                    {{ item[column.id]?.subtext || '-' }}
                  </c-text>
                </c-box>
              </c-box>
              <c-box v-if="column?.type === 'CUSTOM'">
                <c-box v-html="column?.customRender(item)" />
              </c-box>
              <c-box v-if="column?.type == 'PREVIEW'">
                <c-flex align-items="center">
                  <c-image
                    v-if="column?.customRender(item)?.image"
                    :src="column?.customRender(item)?.image"
                    width="60px"
                    height="60px"
                    mr="1rem"
                    object-fit="cover"
                  />
                  <c-box class="line-clamp-3">
                    {{ column?.customRender(item)?.description }}
                  </c-box>
                  <!-- <div v-html="column?.customRender(item)?.description"></div> -->
                </c-flex>
              </c-box>
              <c-box
                v-if="column.type === 'STATUS'"
                display="inline-block"
              >
                <c-text
                  v-if="item[column.id] === '-'"
                >
                  {{ item[column.id] }}
                </c-text>
                <ChipStatus
                  v-else
                  :status="item[column.id]"
                  screen="mobile-only"
                />
              </c-box>
              <c-box
                v-if="column.type === 'BUTTON'"
                display="inline-block"
              >
                <BaseButton
                  v-if="column?.customRender"
                  v-bind="column?.customRender(item)?.bindButton"
                  font-size="14px"
                  border-radius="12px"
                  max-height="36px"
                  v-on="column?.customRender(item)?.onButton"
                >
                  {{ column?.customRender(item)?.buttonText }}
                </BaseButton>
              </c-box>
              <c-box
                v-if="column.type === 'CUSTOM2'"
                display="inline-block"
              >
                <slot
                  :name="column.id"
                  :item="item"
                >
                  aasdasdasd
                </slot>
                <!--                <slot>{{ item[column.id] }}</slot>-->
              </c-box>
            </c-box>
          </c-box>
        </c-box>
      </c-box>
    </c-box>
    <c-flex
      justify="space-between"
      align="center"
      border-top="2px solid #CBD5E0"
      padding="8px 16px"
    >
      <c-box>
        <c-flex align="center">
          <BaseText
            size-mobile="14px-2"
            size-desktop="16px"
          >
            Show
          </BaseText>
          <c-select
            min-width="140px"
            border-radius="8px"
            size="md"
            color="primary.400"
            font-size="18px"
            font-weight="500"
            margin-left="16px"  
            :value="perPage?.toString()"
            @change="(perPage_) => $emit('on-change-per-page', perPage_)"
          >
            <option value="5">
              5 Rows
            </option>
            <option value="10">
              10 Rows
            </option>
          </c-select>
        </c-flex>
      </c-box>
      <c-box
        font-size="14px"
        font-weight="400"
        font-family="Roboto, sans-serif, Arial"
        padding="12px 0"
        display="flex"
        justify-content="center"
        align-items="center"
      >
        <BasePagination
          :page="page"
          :total-page="totalPage"
          @on-change="handleChangePage"
        />
      </c-box>
    </c-flex>
  </c-box>
</template>

<script>
import BasePagination from '@/components/elements/base-pagination.vue'
import ChipStatus from '@/components/elements/chip-status.vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseButton from '@/components/elements/base-button.vue'

export default {
  name: 'BaseTable2',
  components: {
    BaseButton,
    BaseText,
    ChipStatus,
    BasePagination,
  },
  props: {
    sort: {
      type: Object,
      default: () => ({}),
    },
    columns: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 5,
    },
    totalPage: {
      type: Number,
      default: 13,
    },
    multipleSort: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'on-change-page',
    'on-change-sort',
  ],
  methods: {
    handleChangePage(currentPage) {
      this.$emit('on-change-page', currentPage)
    },
    handleChangeSort(columnId, sortable) {
      if (!sortable) {
        return
      }
      const sort = this.sort[columnId]
      let newSort = 'ASC'
      if (sort === 'ASC') {
        newSort = 'DESC'
      }
      this.$emit('on-change-sort', {
        ...(this.multipleSort ? this.sort: {}),
        [columnId]: newSort,
      })
    },
  },
}
</script>

<style scoped>
.base-table__table::v-deep {
  border-collapse: separate;
  border: solid #EDF2F7 1px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.base-table__tbody::v-deep .base-table__tr:not(:last-child) .base-table__td {
  border-bottom: solid #CBD5E0 2px;
}

::v-deep.base-table__th,
::v-deep.base-table__td {
  border-left: none;
  border-right: none;
}

.base-table__th.sortable:hover {
  background-color: #92F3D1;
  cursor: pointer;
}

.base-table__th.sortable:active {
  background-color: #58DCBA;
  cursor: pointer;
}
</style>

<!--/*.base-table__tfoot::v-deep .base-table__tr .base-table__td {*/-->
<!--/*  border-top: solid #CBD5E0 2px;*/-->
<!--/*  //background-color: red;*/-->
<!--//}-->
